/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './Header';
import './normalize.css';
import styles from './Layout.module.css';

function Layout({ renderHero, children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata.title;

  return (
    <>
      <Header isOverlay={!!renderHero} siteTitle={siteTitle} />
      <main className={styles.content}>
        {renderHero ? renderHero() : null}
        <div className={styles.innerContent}>{children}</div>
      </main>
      <footer className={styles.footer}>
        Copyright © {new Date().getFullYear()} {siteTitle}
      </footer>
    </>
  );
}

Layout.propTypes = {
  renderHero: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Layout;
