import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import styles from './MenuButton.module.css';

function MenuButton({ className, isOpen, onToggle }) {
  return (
    <button
      className={classNames(className, isOpen && styles.isOpen)}
      type="button"
      aria-hidden={true}
      onClick={onToggle}
    >
      <span className={styles.menuButtonBox}>
        <span className={styles.menuButtonInner}></span>
      </span>
    </button>
  );
}

MenuButton.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

MenuButton.defaultProps = {
  className: styles.menuButton,
};

export default MenuButton;
