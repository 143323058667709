import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Divider from '../components/Divider';

function ServicesPage() {
  return (
    <Layout>
      <SEO title="Services" />
      <h1>Services</h1>

      <p>
        All services are personalized to your organization, and driven by some
        key building blocks for success. These building blocks were forged
        through my doctoral level expertise and real world experience leading
        EDI in organizations. The work we do together will be personalized to
        your organization and employees, will be informed by current theories
        and research, be results driven, and provide an evaluation component.
      </p>

      <p>
        <strong>Services include:</strong>
        <ul>
          <li>Workshops</li>
          <li>Team Building</li>
          <li>Consulting (often with Human Resources, Executive Teams and Diversity Committees)</li>
          <li>Coaching</li>
          <li>EDI Based Assessment and Benchmarking</li>
          <li>Keynote Speaking</li>
        </ul>
      </p>

      <Divider />

      <h2>Building a Relationship</h2>
      <p>
        Organizations that build a long standing relationship will be more
        successful in the long run. Part of this is that they are already
        primed to commit to this work- they are willing to put the resources
        in to grow in this journey. It is organizations who build long-term
        partnerships that see the most value in this work.
      </p>
      <p>
        Keeping in mind that there is no silver bullet workshop that will
        transform your organization, I am willing to discuss providing a
        single workshop. I don’t want to keep organizations from embarking on
        this journey, and have found that a workshop can often plant the
        seeds necessary to dig a little deeper. We can discuss the needs and
        goals of your organization, to provide a personalized workshop that
        will be beneficial.
      </p>
    </Layout>
  );
}

export default ServicesPage;
