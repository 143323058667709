import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';
import styles from './HeaderLink.module.css';

function HeaderLink({ children, ...otherProps }) {
  return (
    <Link
      className={styles.headerLink}
      activeClassName={styles.activeHeaderLink}
      {...otherProps}
    >
      <span className={styles.headerInnerLink}>{children}</span>
    </Link>
  );
}

HeaderLink.propTypes = {
  children: PropTypes.node,
};

export default HeaderLink;
