import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import Link from './Link';
import HeaderLink from './HeaderLink';
import MenuButton from './MenuButton';
import Logo from '../images/be-brave.svg';
import styles from './Header.module.css';

function preventScroll() {
  document.body.style.position = 'fixed';
  document.body.style.top = 0;
  document.body.style.right = 0;
  document.body.style.bottom = 0;
  document.body.style.left = 0;
}

function restoreScroll() {
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.right = '';
  document.body.style.bottom = '';
  document.body.style.left = '';
}

function Header({ isOverlay, siteTitle }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function onMenuToggle() {
    setIsMenuOpen(!isMenuOpen);
  }

  useEffect(() => {
    isMenuOpen ? preventScroll() : restoreScroll();

    return restoreScroll;
  }, [isMenuOpen]);

  return (
    <header
      className={classNames(
        isOverlay ? styles.overlayHeader : styles.header,
        isMenuOpen && styles.isOpen
      )}
    >
      <MenuButton
        className={styles.menuButton}
        isOpen={isMenuOpen}
        onToggle={onMenuToggle}
      />

      <nav className={styles.innerHeader}>
        <div className={styles.headerPart}>
          <HeaderLink to="/">Home</HeaderLink>
          <HeaderLink to="/approach/">My Approach</HeaderLink>
          <HeaderLink to="/about/">About Me</HeaderLink>
        </div>

        <Link
          to="/"
          className={styles.logoLink}
          activeClassName={styles.activeLogoLink}
        >
          <div className={styles.logoBox}>
            <Logo aria-label={siteTitle} width="100%" height="80%" />
          </div>
        </Link>

        <div className={styles.headerPart}>
          <HeaderLink to="/services/">Services</HeaderLink>
          <HeaderLink to="/testimonials/">Testimonials</HeaderLink>
          <HeaderLink to="/contact/">Contact Us</HeaderLink>
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  isOverlay: PropTypes.bool,
  siteTitle: PropTypes.string.isRequired,
};

export default Header;
