import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styles from "./Link.module.css";

function Link(props) {
  return (
    <GatsbyLink
      className={styles.link}
      activeClassName={styles.activeLink}
      {...props}
    />
  );
}

export default Link;
