import React from 'react';
import PropTypes from 'prop-types';
import styles from './Divider.module.css';

function Divider({ className }) {
  return <div className={className} />;
}

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: styles.divider,
};

export default Divider;
